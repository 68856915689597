import { CustomerUpdateFormProvider } from '@jetshop/core/components/Auth/CustomerUpdateForm';
import t from '@jetshop/intl';
import React, { useState } from 'react';
import { GlobalError } from '../Forms/GlobalError';
import { RadioGroupWithLabels } from '../ui/RadioGroupWithLabels';
import customerProfileQuery from './customerProfileQuery.gql';
import { BlockTitle, ButtonContainer, PrimaryButton } from './ProfilePage';
import Spinner from '../../../src/components/ui/Spinner';

export function Newsletter() {
  const [loading, setLoading] = useState(false);

  return (
    <div className="block" id="newsletter">
      <BlockTitle>{t('Newsletters')}</BlockTitle>
      <CustomerUpdateFormProvider
        customerProfileQuery={customerProfileQuery}
        onSubmit={() => setLoading(true)}
        onSubmitSuccess={() => {
          window.location.reload();
        }}
        render={({ isSubmitting, isValid, values, status }) => {
          if (loading) {
            return <Spinner />;
          } else {
            return (
              <>
                <RadioGroupWithLabels
                  name="preferences.type.acceptsEmail"
                  items={[
                    {
                      label: t('I want newsletters'),
                      value: true
                    },
                    {
                      label: t("I don't want newsletters"),
                      value: false
                    }
                  ]}
                />

                {status && status.globalError && (
                  <GlobalError style={{ marginBottom: '2em' }}>
                    {status.globalError}
                  </GlobalError>
                )}

                <ButtonContainer>
                  <PrimaryButton
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    {t(isSubmitting ? 'Hold on a moment…' : 'Save')}
                  </PrimaryButton>
                </ButtonContainer>
              </>
            );
          }
        }}
      />
    </div>
  );
}
